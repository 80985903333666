body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.header {
  /* Copied from Developer Sam's website. */
  color: #fff;
  background-color: #3e7ae2;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.header .header-container {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 24px;
  min-height: 64px;
  font-size: 1.25rem;
  font-weight: 500;
}

.hidden-below-header {
  height: 100px;
}

.note {
  margin: 1em;
  padding: 1em;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.note .note-header {
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #303846;
  color: white;
  text-align: center;
  padding: 24px;
}

.center {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.hi {
  margin: 1em;
}

.fanArt {
  height: 200px;
  width: 200px;
}
